import React, { Component } from "react"
import "../assets/fonts/font.css"
import "../assets/css/faq.css"

import Footer from '../components/footer';
/* import NewsLatter from '../components/newsletter'; */

import lineImage from "../images/line2.png"
import Layout from "../components/layout"
import handIcon from "../images/hand.svg"
import chatbottom from "../images/chat-bottom-icon.svg"
import chatProfile from "../images/chat-profile.png"
import closeChat from "../images/close-icon.svg"
import SEO from "../components/seo"


class TermsOfService extends Component {

  render() {
    return (
      <Layout rest={this.props} layoutType="main">
        <SEO title="Servicecontract.com - Terms of Sale" keywords={[`Terms of Sale`, `Extended Warranty`]} />
        <div className="chat-content" style={{ display: 'none' }}>
          <div className="chat-content-circle">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.496" viewBox="0 0 30 26.496">
              <g id="Group_157" data-name="Group 157" transform="translate(-309.85 -157.354)">
                <g id="Group_27" data-name="Group 27" transform="translate(309.85 157.354)">
                  <path id="Path_73" data-name="Path 73" d="M309.85,170.605c0,7.3,6.733,13.245,15,13.245a16.468,16.468,0,0,0,7.738-1.9,8.731,8.731,0,0,0,4.123.967,10.089,10.089,0,0,0,1.427-.1,1.14,1.14,0,0,0,.46-2.089,4.986,4.986,0,0,1-1.8-2.132,12.3,12.3,0,0,0,3.045-8c0-7.3-6.733-13.245-15-13.245S309.85,163.3,309.85,170.605Zm1.5,0c0-6.476,6.058-11.744,13.5-11.744s13.5,5.269,13.5,11.744a10.721,10.721,0,0,1-2.971,7.345.756.756,0,0,0-.153.772,6.667,6.667,0,0,0,1.795,2.7,7.254,7.254,0,0,1-4.044-.968.717.717,0,0,0-.386-.1.745.745,0,0,0-.373.1,14.929,14.929,0,0,1-7.37,1.911C317.409,182.349,311.351,177.081,311.351,170.605Z" transform="translate(-309.85 -157.354)" fill="#fff" />
                  <circle id="Ellipse_1" data-name="Ellipse 1" cx="1.924" cy="1.924" r="1.924" transform="translate(13.085 11.327)" fill="#fff" />
                  <circle id="Ellipse_2" data-name="Ellipse 2" cx="1.924" cy="1.924" r="1.924" transform="translate(17.943 11.327)" fill="#fff" />
                  <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.924" cy="1.924" r="1.924" transform="translate(8.227 11.327)" fill="#fff" />
                </g>
              </g>
            </svg>
          </div>
          <div className="chat-box">

            <div className="chatbox-inner">
              <div className="chat-profile">
                <img className="" src={chatProfile} alt="" />
              </div>
              <span className="close-chat"><img className="" src={closeChat} alt="" /></span>
              <p> <img className="hand-icon" src={handIcon} alt="" /> <span>Hi, We're here to help.</span></p>
            </div>
            <img className="chat-bottom" src={chatbottom} alt="" />
          </div>

        </div>

        <section className="faq-page-container terms-container">
          <div className="faq-tab-container">
            <div className="tab-container">
              <h2 className="tab-title">TERMS OF USE</h2>
              <div className="tab-content">
                  

              <p>Servicecontract.com provides a personalized subscription-type service that provides our customers with access to numerous service contract options offered by the best providers and administrators in the business. Please read them carefully. Please also see our Privacy Policy which governs the information we gather from you and how we use it. We may terminate or restrict your use of our site and services as well as your account if you violate these Terms of Use or engage in illegal, fraudulent, or illicit activities in relation to our services. <strong>By using servicecontract.com, you accept and agree to be bound by these Terms of Use set forth below which govern your use of our site and services.</strong></p>
              <p>Servicecontract.com is offered and available to users who 16 years of age or older and reside in the United States or any of its territories or possessions. By using the site, you represent and warrant that you are of legal age to form a binding contract with servicecontract.com and the parties to the service contract you may purchase and meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the site. You agree to be responsible for your use of the site and your account. You warrant that the information supplied by you in using the site is true and accurate. </p>
              <p><strong>Service Contracts</strong></p>
              <p>The service contracts sold to you through servicecontract.com are provided and administered by entities other than servicecontract.com. The coverage outlined in the service contracts and obligations associated with it are those of the provider and administrator as identified in the contract, and not servicecontract.com. We are not responsible for examining or evaluating, and we do not warrant the service contracts or any offerings of the providers or administrators. We do not assume any responsibility or liability for the actions, omissions, obligations, contracts, or products of any third parties or for actual, incidental, or consequential damages. You should carefully review the terms of the service contract before purchasing it. By using our service, you agree to look solely to the providers of the service contracts to satisfy any obligations to you thereunder.</p>
              <p><strong>Exclusions and Cancellations</strong></p>
              <p>See the terms and conditions, including but not limited to the exclusions and cancellation provisions of the service contract you purchase.</p>
              <p><strong>Service Contract Price</strong></p>
              <p>The service contract price rated for your vehicle is for the 30 day term of the contract. We will make all reasonable efforts to maintain that rate for additional service contracts purchased within a 36 month period of the initial purchase. However, we reserve the right to adjust pricing for our service and for the service contracts in any manner and at any time as we may determine in our sole and absolute discretion upon notice to you. </p>
              <p><strong>Billing</strong></p>
              <p>If you purchase a service contract through servicecontract.com, you will be charged the service contract purchase price rated for your vehicle, including surcharges and selected coverages. You may also be charged for participation in our reward plan and for such other programs as you select. The date of your monthly payment will be the same date each month as your service contract purchase date, that is, the date you pay the service contract purchase price. We will process your payment on that day each month or as otherwise in accordance with the terms of the service contract you purchase. If payment is not made for your service contract within three (3) days of your monthly payment date, the service contract will be canceled. Log into your account to view billing information and the details of your transactions. Refunds are only processed pursuant to the terms of the service contract. All purchases through our site or other transactions for the sale of goods or services formed through our site are governed by our Terms of Sale which are incorporated by reference into these Terms of Use.</p>
              <p><strong>Payment Method</strong></p>
              <p>To use the servicecontract.com service, you must provide us with a valid payment method. This payment method will remain on file in your account.  You authorize us to charge any and all payment methods associated with your account in order to pay for the service contract you purchase. You remain responsible to servicecontract.com and the provider and administrator for any uncollected fees resulting from insufficient funds, expiration of your payment method, or otherwise. Certain payment methods may result in additional charges and fees to you resulting from the processing of payment. Please contact your payment provider for additional details. You can update your payment method or add additional payment methods by logging into your account. </p>
              <p><strong>Your Account</strong></p>
              <p>In order to save a quote or purchase a service contract, you must create an account. In order to maintain control over the account and ensure it is not compromised, please store your login credentials in a safe location and do not permit anyone access to the account. You must treat your user name, pass word, and any other piece of information as part of our security procedures as confidential. You also acknowledge that your account is personal to you and agree not to provide any other person with access to the site or portions of it using your user name, password, or other security information. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.</p>
              <p>You are responsible for the accuracy of the information you provide in your account. It is a condition of your use of the site that all information you provide must be correct, current and complete. You agree that all information you provide in order to register and create an account with the site or otherwise, including through the use of interactive features on the site, is governed by our Privacy Notice and you consent to all actions taken with respect to your information consistent with our Privacy Notice.  We have the right to disable any user name, password, or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Use. In addition, we may suspend or terminate your account without credit in order to protect you from fraud or other illicit activity, or if we determine that you are attempting to conduct such activity through the use of your account. </p>
              <p><strong>Electronic Communications</strong></p>
              <p>Information relating to your account and service contract will be sent to you electronically only according to the preferences you select. You consent to receive communications electronically from such as emails, texts, mobile push notices, app communications, notices, messages, chats, and information through our site. You agree that electronic notice satisfies any legal communication requirements, including that such communications be in writing.</p>
              <p><strong>Customer Support</strong></p>
              <p>For assistance with your account, please contact our customer support team via chat, email, or phone at any time. </p>
              <p><strong>Prohibited Use</strong></p>
              <p>You may use the site only for lawful purposes and in accordance with these Terms of Use. You agree not to use the site:</p>
              <ul className="priul">
                <li>In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries). </li>
                <li>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.</li>
                <li>To send, knowingly receive, upload, download, use, or re-use any material that does not comply with our Content Standards.</li>
                <li>To transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any "junk mail," "chain letter," "spam," or any other similar solicitation.</li>
                <li>To impersonate or attempt to impersonate the servicecontract.com, a servicecontract.com employee, another user, or any other person or entity (including, without limitation, by using email addresses or user ids associated with any of the foregoing).</li>
                <li>To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Site, or which, as determined by us, may harm the servicecontract.com or users of the site, or expose them to liability.</li>
              </ul>
              <p>Additionally, you agree not to:</p>
              <ul className="priul">
                <li>Use the site in any manner that could disable, overburden, damage, or impair the site or interfere with any other party's use of the Site, including their ability to engage in real time activities through the site.</li>
                <li>Use any robot, spider, or other automatic device, process, or means to access the Site for any purpose, including monitoring or copying any of the material on the site.</li>
                <li>Use any manual process to monitor or copy any of the material on the site, or for any other purpose not expressly authorized in these Terms of Use, without our prior written consent.</li>
                <li>Use any device, software, or routine that interferes with the proper working of the Site.</li>
                <li>Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.</li>
                <li>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the site, the server on which the Site is stored, or any server, computer, or database connected to the site. </li>
                <li>Attack the site via a denial-of-service attack or a distributed denial-of-service attack.</li>
                <li>Otherwise attempt to interfere with the proper working of the site.</li>
              </ul>
              <p><strong>Links from Site</strong></p>
              <p>If the<strong> </strong>site contains links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements, and sponsored links. We have no control over the contents of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third-party sites linked to this site, you do so entirely at your own risk and subject to the terms and conditions of use for such sites.</p>
              <p><strong>Intellectual Property Rights</strong></p>
              <p>The servicecontract.com  and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by the servicecontract.com, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
              <p>Subject to your compliance with these Terms of Use, servicecontract.com grants you a limited, non-exclusive, non-transferable, non-sublicensable license to access and make personal use of the servicecontract.com site in order to purchase service contracts. You agree to use servicecontract.com and the services it provides, including all features and functionalities thereof in accordance with all applicable laws and these Terms of Use. Except as explicitly authorized, you agree not to archive, download, copy, reproduce, distribute, modify, publish, license, sell, resell, exploit, or create derivate works from, use data mining, robots, or other data gathering and extraction tools, or otherwise use or misuse or manipulate the content or information provided to you through servicecontract.com. The license granted to you immediately terminates if you do not comply with these Terms of Use at any time. </p>
              <p><strong>Trademarks </strong></p>
              <p>The servicecontract.com name, the servicecontract.com logo, and all related names, logos, product and service names, designs, and slogans are trademarks of servicecontract.com or its affiliates or licensors. You must not use such marks without the prior written permission of the servicecontract.com. All other names, logos, product and service names, designs, and slogans on the site are the trademarks of their respective owners.</p>
              <p><strong>Disclaimer of Warranties and Limitations on Liability </strong></p>
              <p><strong>SERVICECONTRACT.COM SPECIFICALLY AND EXPLICITLY DISCLAIMS ANY LIABILITY ASSOCIATED WITH THE SERVICE CONTRACTS PURCHASED THROUGH ITS SITE. TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL SERVICECONTRACT.COM, ITS AFFILIATES, SUBSIDIARIES, PARENT, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SITE, ANY SITES LINKED TO IT, ANY CONTENT ON THE SITE OR SUCH OTHER SITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.</strong></p>
              <p>THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
              <p><strong>THE SERVICES OF SERVICECONTRACT.COM AND INFORMATION PROVIDED THEREON ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITH ALL FAULTS AND WITHOUT ANY KIND OF WARRANTY, EXPRESS OR IMPLIED. SERVICECONTRACT.COM DOES NOT GUARANTEE THE PERFORMANCE OF OR MAKE ANY REPRESENTATIONS AS TO THE SERVICE CONTRACTS MADE AVAILABLE FOR PURCHASE THROUGH ITS SERVICE AND SITE. </strong>NEITHER THE SERVICECONTRACT.COM NOR ANY PERSON ASSOCIATED WITH THE SERVICECONTRACT.COM MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE SERVICECONTRACT.COM NOR ANYONE ASSOCIATED WITH THE SERVICECONTRACT.COM REPRESENTS OR WARRANTS THAT THE SITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE SITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>
              <p>TO THE FULLEST EXTENT PROVIDED BY LAW, THE SERVICECONTRACT.COM HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</p>
              <p>You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the site will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY SITE LINKED TO IT.</p>
              <p>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
              <p><strong>Arbitration </strong></p>
              <p>We ask that you contact one of our customer service representatives regarding any questions, concerns, or disputes you may have so that we may resolve them as soon as possible. If we are unable to satisfy your concerns, you agree that you will resolve any dispute, claim, or controversy you have with servicecontract.com including but not limited to those arising out of or relating to these Terms of Use, the services provided, and the service contracts made available to you through our site through binding arbitration or in small claims court in states where arbitration is not permitted to be required. If you seek arbitration or to file a claim in small claims court, you must first send notice of your dispute to servicecontract.com with a description of the nature and basis for the dispute and the specific relief sought at least 30 days prior to taking such action. Please end all notices to servicecontract.com, legal@servicecontract.com. Arbitration will be governed by the commercial arbitration rules of the American Arbitration Association. The rules and forms relating to the arbitration are available at www.adr.org or you an contact the AA by calling 1800-778-7879. The arbitrator’s decision shall be final and binding on all parties except for judicial review as permitted by law, and review and enforcement of injunctive relief wherein the court shall not be bound by the legal conclusions of the arbitrator. The Federal Arbitration Act and federal arbitration law apply to these Terms of Use.</p>
              <p><strong>Limitation on Time to File Claims</strong></p>
              <p>ANY ARBITRATION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE SITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH ARBITRATION PROCEEDING, CAUSE OF ACTION, OR CLAIM IS PERMANENTLY BARRED.</p>
              <p><strong>Indemnification</strong></p>
              <p>You agree to defend, indemnify, and hold harmless the servicecontract.com its affiliates, subsidiaries, parent, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Use or your use of the site, including, but not limited to, your User Contributions, any use of the Site's content, services, and products other than as expressly authorized in these Terms of Use, or your use of any information obtained from the site.</p>
              <p><strong>Class Action Waiver</strong></p>
              <p><strong>YOU AGREE THAT YOU MAY ONLY BRING CLAIMS AGAINST SERVICECONTRACT.COM IN YOUR INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR MEMBER OF A CLASS IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.</strong> In addition, unless consented to by serviecontract.com, more than one individual’s claims may not be consolidated at arbitration or in small claims court.</p>
              <p><strong>Governing Law </strong></p>
              <p>These Terms of Use and your use of servicecontract.com and its services as well as any dispute that may arise are governed by and interpreted and construed in accordance with the laws of the state of New Jersey without regard to its conflict of laws provisions. These terms will not limit any consumer protection rights that you may have under the laws of the state where you reside. </p>
              <p><strong>Survival</strong></p>
              <p>The unenforceability, invalidity, or illegality of any provision of these Terms of Use shall not render the other provisions unenforceable, invalid, or illegal, and the remaining provisions shall remain in full force and effect.</p>
              <p><strong>Changes to Terms of Use</strong></p>
              <p>Servicecontract.com may revise and update these Terms of Use, our site, and our policies from time to time in its sole and absolute discretion. Any revisions are effective immediately for individuals who are not holders of active service contracts and apply to all access to and use of the site thereafter, and effective 30 days from posting for all individuals with an active service contract. Your continued use of the site following the posting of revised <strong>Terms</strong> of <strong>Use</strong> means that you accept and agree to the changes. You are expected to check this page from time to time so you are aware of any changes, as they are binding on you.</p>
              <p><strong>Entire Agreement</strong></p>
              <p>The Terms of Use and our Privacy Notice constitute the sole and entire agreement between you and servicecontract.com regarding the site and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the site.</p>

              <p><strong>Your Questions, Comments, and Concerns</strong></p>
              <p>This site is operated by servicecontract.com, Inc. All notices of copyright infringement claims should be sent to legal@servicecontract.com. All other feedback, comments, requests for technical support, and other communications relating to the site should be directed to <a href="mailto:help@servicecontract.com">help@servicecontract.com</a>.</p>



              </div>
            </div>
          </div>
        </section>
        {/* <NewsLatter /> */}
        <div className="break-section">
          <img src={lineImage} alt="line" />
        </div>
        <Footer />
      </Layout>
    );
  }
}


export default TermsOfService
