import './header.css';

import { Link } from 'gatsby';
import React, { Component } from 'react';

import callIcon from '../images/call-icon.svg';
import livechat from '../images/live-chat-icon.svg';
import Logo from '../images/uat-sc-logo.png';
import mailIcon from '../images/mail-icon.svg';

class Footer extends Component {

  componentDidMount = () => {
    
    /* const script = document.createElement("script");
    script.id = "Microsoft_Omnichannel_LCWidget";
    script.src = "https://oc-cdn-ocprod.azureedge.net/livechatwidget/scripts/LiveChatBootstrapper.js";
    //script.async = true;
    script.setAttribute('data-app-id', '90f55c2d-621a-4a48-81af-fcd319742f0e');
    script.setAttribute('data-org-id', '7f9acfc5-ee61-4db6-90b7-21bde9dfb3f2');
    script.setAttribute('data-org-url', 'https://orgb0bd5174-crm.omnichannelengagementhub.com');
    document.body.appendChild(script); */
}

  render() {
    const isPartiallyActive = ({ isPartiallyCurrent }) => {
      return isPartiallyCurrent
        ? { className: "active" }
        : null
    }

    return (
      <>
        <section className={'contact-container'+(global.dealertoken?' hideme':'')}>
          <div className="container">
            {/* <div className="logo-container">
              <img src={Logo} alt="Logo" />
            </div> */}
            <div className="contact-inner">
              
              <div className="focol fc1">
                <ul>
                  <li>
                    <div className="fimg"><img src={mailIcon} alt="mail" /></div>
                    <div className="flnk">
                      <a href={`mailto:${'help@servicecontract.com'}`}>
                      help@servicecontract.com<br /><span>24/7-Available when you need us</span>
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="fimg"><img src={callIcon} alt="call" /></div>
                    <div className="flnk">
                    <a href={`tel:${'18008003629'}`}>1-800-800-3629</a>
                    </div>
                  </li>
                  <li className="footer-chat-link"> <img src={livechat} alt="liev chat" /><p>Live Chat</p></li>
                </ul>
              </div>
              <div className="focol fc2">
                <ul>
                  <li><a href="/dealer/" target="_blank">Dealer</a></li>
                  <li><Link activeClassName="active" getProps={isPartiallyActive} to="/vehicleInfo">Get Your Quote</Link></li>
                  {/* <li><Link activeClassName="active" getProps={isPartiallyActive} to="/signup">Dealer Sign Up</Link></li> */}
                  <li><Link activeClassName="active" getProps={isPartiallyActive} to="/covered">Coverage Plans</Link></li>
                  {/* <li><Link activeClassName="active" getProps={isPartiallyActive} to="/benefits">Extra Benefits</Link></li>
                  <li><Link activeClassName="active" getProps={isPartiallyActive} to="/claims">Claims</Link></li> */}
                  {/* <li><Link activeClassName="active" getProps={isPartiallyActive} to="/partners">Our Partners</Link></li> */}
                  
                  <li><Link activeClassName="active" getProps={isPartiallyActive} to="/contact">Contact</Link></li>
                  {/* <li><Link activeClassName="active" getProps={isPartiallyActive} to="/api">API</Link></li> */}
                </ul>
              </div>
              <div className="focol fc3">
                <ul>
                  {/* <li><Link activeClassName="active" getProps={isPartiallyActive} to="/who-we-are">Who We Are</Link></li> */}
                  {/* <li><Link activeClassName="active" getProps={isPartiallyActive} to="/blog">Blog</Link></li> */}
                  
                  {/* <li><Link to="/">Join the Team</Link></li> */}
                  <li><Link activeClassName="active" getProps={isPartiallyActive}  to="/faq">FAQs</Link></li>
                  <li><Link activeClassName="active" getProps={isPartiallyActive} to="/legalstuff">Legal Stuff</Link></li>
                  <li><Link activeClassName="active" getProps={isPartiallyActive} to="/privacy">Privacy Policy</Link></li>
                  <li><Link activeClassName="active" getProps={isPartiallyActive} to="/terms">Terms of Service</Link></li>
                </ul>
              </div>
              <div className="focol fc4">
                
                  <Link activeClassName="active" getProps={isPartiallyActive}  to="/">
                    <img src={Logo} alt="Logo" />
                  </Link>
                
                {/* <div className="store-icon">
                  <h6>Get Our App</h6>
                  <span><img src={appStoreIcon} alt="Apple store" /></span>
                  <span><img src={googlePlayIcon} alt="Googole play store" /></span>
                </div> */}

                {/* <div className="follow-icon">
                  <h6>Follow Us</h6>
                  <span><img src={fbIcon} alt="facebook" /></span>
                  <span><img src={twitterIcon} alt="Twitter" /></span>
                  <span><img src={instacon} alt="instagra," /></span>
                  <span><img src={video} alt="youtube" /></span>
                </div> */}

              </div>
            </div>
          </div>
        </section>
        <section className="copyright-container">
          <div className="container">
            <div className="copyright">
              <small>Copyright servicecontract.com © {new Date().getFullYear()}</small>
            </div>
          </div>
        </section>
      </>
    )

  }

}

export default Footer

